import { useFetch } from "../utilities/react-query";

const configURL = "/web-twinprocms/config";

export const useGetConfig = () => {
  const response: any = useFetch<any[]>(configURL);
  return {
    ...response,
  };
};
