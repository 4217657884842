export const roles = [
  { value: "Uberadmin", label: "Uberadmin" },
  { value: "Superadmin", label: "Superadmin" },
  { value: "Developer", label: "Developer" },
  { value: "Experience Designer", label: "Experience Designer" },
  { value: "Viewer", label: "Viewer" },
  { value: "Product Manager", label: "Product Manager" },
];

export const statusOptions = [
  { value: "DRAFT", label: "DRAFT", key: "DRAFT" },
  { value: "PUBLISHED", label: "PUBLISHED", key: "PUBLISHED" },
];
