import React, { useEffect, useRef, useState } from "react";
import styleCarousal from "./Carousal.module.css";
import ProductCard from "../ProductCard/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Carousel } from "antd";

// import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";

const Carousal = ({
  sidePanelDetail,
  isLoading,
  onCarouselStateChange,
  productCategories,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  console.log(productCategories, "productCategories");

  const ref = useRef();

  useEffect(() => {
    onCarouselStateChange(currentIndex);
  });

  const showPreviousProduct = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? productCategories?.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
    onCarouselStateChange(newIndex);
    // setProducts(sidePanelDetail?.related_products[currentIndex]);
  };

  const showNextProduct = () => {
    const isLastSlide = currentIndex === productCategories?.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    onCarouselStateChange(newIndex);
  };

  return (
    <section className={styleCarousal["carousal-container"]}>
      <Carousel ref={ref} className={styleCarousal["product-carousel"]}>
        {productCategories?.length > 0 &&
          productCategories?.map(item => {
            console.log(item, "item");
            return (
              <ProductCard
                sidePanelDetail={sidePanelDetail}
                isLoading={isLoading}
                currentIndex={currentIndex}
                productCategories={productCategories}
                image={item?.image}
                name={item?.name}
                description={item?.description}
              />
            );
          })}
      </Carousel>

      <div className={styleCarousal["carousal-btn-container"]}>
        <Button
          onClick={() => {
            ref.current.prev();
            showPreviousProduct();
          }}
        >
          {" "}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={styleCarousal["arrow-icon"]}
          />{" "}
        </Button>
        <Button
          onClick={() => {
            ref.current.prev();
            showNextProduct();
          }}
        >
          {" "}
          <FontAwesomeIcon
            icon={faArrowRight}
            className={styleCarousal["arrow-icon"]}
          />{" "}
        </Button>
      </div>
    </section>
  );
};

export default Carousal;
